/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { Box, Container, Flex, Heading, ThemeUICSSObject, jsx } from "theme-ui"
import { Formik, Form, Field } from "formik"
import { Global, css } from "@emotion/react"
import { graphql, PageProps } from "gatsby"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
  StaticImage,
} from "gatsby-plugin-image"

import type { NraPageQuery } from "../../graphql-types"

import AguilaButton from "components/AguilaButton"
import LineAnimation from "components/common/LineAnimation"
import LinkedLogo from "components/common/LinkedLogo"
import Seo from "components/seo"

const labelStyles: ThemeUICSSObject = {
  bottom: 0,
  color: "background",
  cursor: "text",
  display: "block",
  fontFamily: "body",
  fontSize: "16px",
  fontWeight: "400",
  left: "30px",
  lineHeight: "150%",
  position: "absolute",
  transform: "translateY(-125%)",
  transition: "transform 0.1s ease",
}

const inputStyles: ThemeUICSSObject = {
  backgroundColor: "transparent",
  borderBlockEndColor: "background",
  borderBlockEndStyle: "solid",
  borderBlockEndWidth: "1px",
  borderBlockStart: "none",
  borderInline: "none",
  color: "background",
  display: "flex",
  fontFamily: "body",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "150%",
  minWidth: 0,
  outline: "none",
  paddingBlock: "6px",
  paddingInline: "30px",
  transition: "border-color 0.3s ease",
  width: "100%",
  ":focus": {
    borderBlockEndColor: "primary",
  },
  ":not(:placeholder-shown) ~ label": {
    transform: "translateY(0%)",
    fontSize: "14px",
  },
  '&[aria-invalid="true"]': {
    borderBlockEndColor: "secondary",
  },
}

// const validateCompany = (value: string) => {
//   let error = undefined
//   if (!value) {
//     error = "Company is required"
//   }
//   return error
// }

const validateLastName = (value: string) => {
  let error = undefined
  if (!value) {
    error = "Last Name is required"
  }
  return error
}

const validateFirstName = (value: string) => {
  let error = undefined
  if (!value) {
    error = "First Name is required"
  }
  return error
}

const validateEmail = (value: string) => {
  let error = undefined
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  if (!value) {
    error = "Email is Required"
  } else if (!emailRegex.test(value)) {
    error = "Invalid email address"
  }
  return error
}

const validatePhoneNumber = (value: string) => {
  let error = undefined
  if (!value) {
    error = "Phone Number is Required"
  } else if (
    !/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/i.test(
      value
    )
  ) {
    error = "Invalid mobilephone number"
  }
  return error
}

export interface HomePageProps extends PageProps {
  data: NraPageQuery
}

declare global {
  interface Window {
    hbspt: any
  }
}

const NRAPage = ({ data }: HomePageProps) => {
  const [formSuccess, setFormSuccess] = useState(false)

  useEffect(() => {
    if (formSuccess) {
      setTimeout(function () {
        setFormSuccess(false)
      }, 5000)
    }
  }, [formSuccess])

  interface valuesType {
    company?: string
    email: string
    firstname: string
    jobtitle: string
    lastname: string
    mobilephone: string
  }
  const handleSubmit = (
    values: valuesType,
    { resetForm, setErrors, setFieldError }: any
  ) => {
    var xhr = new XMLHttpRequest()
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/8105048/402bb8f7-b3c4-4fc3-a039-8781ed66a97e"

    // Example request JSON:
    var data = {
      // submittedAt: Date().toLocaleString(),
      fields: [
        {
          name: "email",
          value: values.email,
        },
        {
          name: "jobtitle",
          value: values.jobtitle,
        },
        {
          name: "company",
          value: values.company,
        },
        {
          name: "firstname",
          value: values.firstname,
        },
        {
          name: "lastname",
          value: values.lastname,
        },
        {
          name: "mobilephone",
          value: values.mobilephone,
        },
      ],
      context: {
        // hutk: ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: location.href,
        pageName: `151st NRA`,
      },
    }

    var final_data = JSON.stringify(data)

    xhr.open("POST", url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json")

    xhr.onreadystatechange = function () {
      // console.log(xhr.status, xhr.responseText)

      if (xhr.readyState == 4 && xhr.status == 200) {
        setFormSuccess(true)
        resetForm()
        // setFormSuccess(true) // alert(xhr.responseText) // Returns a 200 response if the submission is successful.
      } else if (xhr.readyState == 4 && xhr.status == 400) {
        const hubspotResponse = JSON.parse(xhr.responseText)
        if (hubspotResponse.errors[0].errorType === "INVALID_EMAIL") {
          // setErrors({ fields: { email: "Invalid email address" } })
          setFieldError("email", "Invalid email")
          // setFormSuccess(true)
        } else {
          alert(xhr.responseText) // Returns a 400 error the submission is rejected.
        }
      } else if (xhr.readyState == 4 && xhr.status == 403) {
        console.log(403)
        alert(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        console.log(404)
        alert(xhr.responseText) //Returns a 404 error if the formGuid isn't found
      }
    }

    xhr.send(final_data)
  }

  const fullBanner = getImage(data?.fullBanner as ImageDataLike)
  const fullBannerInverse = getImage(data?.fullBannerInverse as ImageDataLike)

  useEffect(() => {
    const scrollLink = document.querySelector('a[href="#form-section"]')
    const formSection = document.querySelector("#form-section")

    if (scrollLink && formSection) {
      scrollLink.addEventListener("click", event => {
        event.preventDefault()
        formSection.scrollIntoView({ behavior: "smooth" })
      })
    }
  }, [])

  return (
    <>
      <Seo
        seo={{
          metaTitle: "151st NRA",
        }}
      />

      <Global
        styles={css`
          @font-face {
            font-family: "Hudson NY Regular";
            font-weight: 400;
            font-style: normal;
            src: url("/fonts/hudson-ny-regular.otf") format("opentype");
            /* src: url("Rubik.woff2") format("woff2"), url("Rubik.woff") format("woff"); */
            font-display: swap;
          }
          @font-face {
            font-family: "D-DIN";
            font-weight: 400;
            font-style: normal;
            src: url("/fonts/d-din.otf") format("opentype");
            /* src: url("Rubik.woff2") format("woff2"), url("Rubik.woff") format("woff"); */
            font-display: swap;
          }
          @font-face {
            font-family: "D-DIN Bold";
            font-weight: 800;
            font-style: normal;
            src: url("/fonts/d-din-bold.otf") format("opentype");
            /* src: url("Rubik.woff2") format("woff2"), url("Rubik.woff") format("woff"); */
            font-display: swap;
          }
        `}
      />

      {/* <Box
        as="header"
        sx={{
          paddingBlockStart: "43px",
          paddingBlockEnd: "100px",
          position: "relative",
        }}
      >
        <LineAnimation variant="thin" />
        <Flex
          sx={{
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 18,
          }}
        >
          <LinkedLogo />
        </Flex>
      </Box> */}

      <Box as="main">
        <Flex
          as="section"
          sx={{
            position: "relative",
            flexDirection: "column",
            gap: 8,
            py: 8,
            pb: 64,
          }}
        >
          <StaticImage
            src={"../images/nra-giveaway/nra-bg-v2.png"}
            alt=""
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: -1,
            }}
          />
          <StaticImage
            src={"../images/nra-giveaway/nra-03.png"}
            alt="aguila logo"
            sx={{
              width: "80%",
              maxWidth: "600px",
              alignSelf: "center",
            }}
          />
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: ["column", null, null, "row"],
              gap: 8,
            }}
          >
            <StaticImage
              src={"../images/nra-giveaway/nra-04.png"}
              alt="win 1000 rounds of ammo graphic"
              sx={{
                width: "80%",
                maxWidth: "500px",
                alignSelf: "center",
              }}
            />
            <StaticImage
              src={"../images/nra-giveaway/nra-06.png"}
              alt="9mm ammo box graphic"
              sx={{
                width: "80%",
                maxWidth: "500px",
                alignSelf: "center",
              }}
            />
          </Flex>
          {/* add a link that when clicked will scroll down ot the form. */}
          <Box
            as="a"
            href="#form-section"
            sx={{
              alignSelf: "center",
              p: 0,
              m: 0,
              display: "block",
              width: "80%",
              maxWidth: "250px",
              cursor: "pointer",
            }}
          >
            <StaticImage
              src={"../images/nra-giveaway/nra-05.png"}
              alt="click here to enter button graphic"
            />
          </Box>
        </Flex>
        <Box
          as="section"
          sx={{
            backgroundColor: "text",
            paddingBlock: "12px",
            color: "background",
          }}
        >
          {/* <Box sx={{ textAlign: "center", paddingInline: "16px" }}>
            <GatsbyImage image={fullBannerInverse as IGatsbyImageData} alt="" />
          </Box> */}
          <Container
            sx={{
              paddingBlock: "170px",
              maxWidth: "505px",
              marginInline: "auto",
            }}
          >
            <Heading
              as="h1"
              sx={{
                fontSize: ["24px", "24px"],
                textAlign: "center",
                color: "secondary",
                marginBlockEnd: 4,
                opacity: formSuccess ? 1 : 0,
                transition: "opacity 0.3s ease",
              }}
            >
              The form has been accepted.
            </Heading>

            <Formik
              initialValues={{
                company: "",
                email: "",
                firstname: "",
                jobtitle: "",
                lastname: "",
                mobilephone: "",
              }}
              onSubmit={handleSubmit}
            >
              {({ errors }) => (
                <Form id="form-section">
                  <Box>
                    <Box
                      sx={{
                        paddingBlockEnd: "24px",
                        position: "relative",
                      }}
                    >
                      <Field
                        aria-invalid={errors.firstname ? true : false}
                        placeholder=" "
                        id="firstname"
                        name="firstname"
                        type="text"
                        validate={validateFirstName}
                        sx={inputStyles}
                      />
                      <label htmlFor="firstname" sx={labelStyles}>
                        First name <span sx={{ color: "secondary" }}>*</span>
                      </label>
                    </Box>

                    {errors.firstname && (
                      <span
                        role="alert"
                        sx={{
                          display: "block",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {errors.firstname}
                      </span>
                    )}
                  </Box>

                  <Box>
                    <Box
                      sx={{
                        marginBlockStart: "26px",
                        paddingBlockEnd: "24px",
                        position: "relative",
                      }}
                    >
                      <Field
                        aria-invalid={errors.lastname ? true : false}
                        placeholder=" "
                        id="lastname"
                        name="lastname"
                        type="text"
                        validate={validateLastName}
                        sx={inputStyles}
                      />
                      <label htmlFor="lastname" sx={labelStyles}>
                        Last name <span sx={{ color: "secondary" }}>*</span>
                      </label>
                    </Box>

                    {errors.lastname && (
                      <span
                        role="alert"
                        sx={{
                          display: "block",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {errors.lastname}
                      </span>
                    )}
                  </Box>

                  <Box
                    sx={{
                      marginBlockStart: "26px",
                      paddingBlockEnd: "24px",
                      position: "relative",
                    }}
                  >
                    <Field
                      aria-invalid={errors.email ? true : false}
                      placeholder=" "
                      id="email"
                      name="email"
                      type="email"
                      validate={validateEmail}
                      sx={inputStyles}
                    />
                    <label htmlFor="email" sx={labelStyles}>
                      Email Address <span sx={{ color: "secondary" }}>*</span>
                    </label>
                  </Box>
                  {errors.email && (
                    <span
                      role="alert"
                      sx={{
                        display: "block",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      {errors.email}
                    </span>
                  )}

                  <Box
                    sx={{
                      marginBlockStart: "26px",
                      paddingBlockEnd: "24px",
                      position: "relative",
                    }}
                  >
                    <Field
                      aria-invalid={errors.jobtitle ? true : false}
                      placeholder=" "
                      id="jobtitle"
                      name="jobtitle"
                      type="tel"
                      sx={inputStyles}
                    />
                    <label htmlFor="jobtitle" sx={labelStyles}>
                      Job Title
                    </label>
                  </Box>

                  {errors.jobtitle && (
                    <span
                      role="alert"
                      sx={{
                        display: "block",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      {errors.jobtitle}
                    </span>
                  )}

                  <Box
                    sx={{
                      marginBlockStart: "26px",
                      paddingBlockEnd: "24px",
                      position: "relative",
                    }}
                  >
                    <Field
                      // aria-invalid={errors.company ? true : false}
                      placeholder=" "
                      id="company"
                      name="company"
                      type="tel"
                      sx={inputStyles}
                    />
                    <label htmlFor="company" sx={labelStyles}>
                      Company
                    </label>
                  </Box>

                  {errors.company && (
                    <span
                      role="alert"
                      sx={{
                        display: "block",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      {errors.company}
                    </span>
                  )}

                  <Box
                    sx={{
                      marginBlockStart: "26px",
                      paddingBlockEnd: "24px",
                      position: "relative",
                    }}
                  >
                    <Field
                      aria-invalid={errors.mobilephone ? true : false}
                      placeholder=" "
                      id="mobilephone"
                      name="mobilephone"
                      type="tel"
                      validate={validatePhoneNumber}
                      sx={inputStyles}
                    />
                    <label htmlFor="mobilephone" sx={labelStyles}>
                      Phone Number <span sx={{ color: "secondary" }}>*</span>
                    </label>
                  </Box>

                  {errors.mobilephone && (
                    <span
                      role="alert"
                      sx={{
                        display: "block",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      {errors.mobilephone}
                    </span>
                  )}

                  <AguilaButton
                    variant="textOnly"
                    type="submit"
                    text="submit"
                    sx={{
                      marginBlockStart: "38px",
                      backgroundColor: "transparent",
                    }}
                  />
                </Form>
              )}
            </Formik>
          </Container>
          {/* <Box sx={{ textAlign: "center", paddingInline: "16px" }}>
            <GatsbyImage image={fullBannerInverse as IGatsbyImageData} alt="" />
          </Box> */}
        </Box>
      </Box>
    </>
  )
}

export default NRAPage

export const query = graphql`
  query NRAPage {
    fullBanner: file(relativePath: { eq: "full-banner.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    fullBannerInverse: file(relativePath: { eq: "full-banner-inverse.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    bulletImage: file(relativePath: { eq: "hero-bullet-group-2x-trimmy.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    heroImage: file(relativePath: { eq: "nra-hero.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
  }
`
